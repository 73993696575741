import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import { Logout } from '../../../services/contributorServices';
import CustomIconButtons from '../Buttons/IconButtons';
import { Avatar, Badge, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Navbar({ visible, headingTitle, headingSubtitle, rightComp }) {
  const navigate = useNavigate();

  if (!visible) {
    return null;
  }

  const data = JSON.parse(localStorage.getItem("contibuter_data"));
  const logOut=async(e)=>{
    e.preventDefault();
    let resp=await Logout();
    if(resp?.status===200){
      // localStorage.removeItem("contibuter_data");
      // localStorage.removeItem("contibuter_token");
      localStorage.clear();
     window.location.href = "/contributor/signin";
    }
  }
  return (
    <>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{
          //  width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`
          boxShadow: 'none',
          '.MuiToolbar-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        color="appColor"
      >
        <Toolbar
          sx={{
            padding: '19px 0',
          }}
        >
          <div style={{ marginRight: 30, flex: 1 }}>
            <Typography variant="h2" noWrap component="div">
              {headingTitle}
            </Typography>
            <Typography variant="h6" noWrap component="div">
              {headingSubtitle}
            </Typography>
          </div>
          <div>
            {rightComp && rightComp}
            {/* <CustomIconButtons
              sx={{ padding: '13px 15px',hover:'none' }}
              color={'iconButtonNavbar'}
              
            > */}
              {/* <Badge badgeContent={4} color="badgePrimary">
                <MailIcon color="action" />
                <Notifications fontSize="inherit" />
              </Badge> */}
              <></>
              <Toolbar >
                <Typography
                  type="title"
                  color="inherit"
                  style={{ borderRight: '0.1em solid black', padding: '0.5em', fontWeight: 900 }}
                >
                 {data &&<span>Welcome {data?.name}</span>  }
                </Typography>

                <Typography type="title" color="inherit" style={{ padding: '0.5em' , fontWeight: 900,cursor:'pointer'}}>
                 <span  style={{'background-color':'#A9A9A9',color:'white',border:'2px solid #A9A9A9' ,margin:'2px',padding:'4px','border-radius':'7px'}} onClick={(e)=>logOut(e)}> Log out</span>
                </Typography>
                <CustomIconButtons
              sx={{ padding: '0' }}
              color={'iconButtonPrimary'}
            >
              <Avatar
                sx={{
                  height: 50,
                  width: 50,
                  borderRadius: 5,
                }}
                onClick={()=>{navigate("/contributor/updateInfo"); console.log("clicking")}}
              />
            </CustomIconButtons>
              </Toolbar>
               {/* <Divider orientation="vertical" flexItem /> */}
           
           
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;

Navbar.defaultProps = {
  visible: true,
  rightComp: null,
};
