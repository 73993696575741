import axios from "axios";
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300 } from "../data/constants";
import {  errorMessage } from "../lib/helper";
import { toast } from "react-toastify";
import { setImagesData, setImagesDataError, setLoading } from "../redux/contributorImages";
import contributoraxois from "../AxiosInterceptor";
import { setSoldImagesHistoryData, setSoldImagesHistoryDataError } from "../redux/contributorSoldImagesHistory";
export const GetAllConributorImageData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp =await contributoraxois?.post(`${ApiConfig?.getAllConributorImageData}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setImagesData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setImagesDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const contributorFindImages = async ( payload) => {
 
    try {
      const response = await contributoraxois?.post(`${ApiConfig?.getAllConributorImageData}`, payload);
      if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
        return response;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return e;
    }
  
};

export const Logout=async()=>{
  try {
    const response = await contributoraxois?.post(`${ApiConfig?.contributorLogout}`);
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      return response;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
    return e;
  }

}

export const changeContributorPwd=async(payload)=>{
  try{
    const response=await contributoraxois?.post(`${ApiConfig?.contributorResetPWD}`,payload);
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      toast.success(
        `passsword reset Successfully`,
        {
          autoClose: 2500,
          className: "toast-message",
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return response;
    }

  }
  catch(e){
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
    return e;

  }
}
export const changeContributorInfo=async(payload)=>{
  try{
    const response=await contributoraxois?.post(`${ApiConfig?.contributorInfoUpdate}`,payload);
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      toast.success(
        "Email Updated Successfully!",
        {
          autoClose: 2500,
          className: "toast-message",
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return response;
    }

  }
  catch(e){
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
    return e;

  }
}

export const contributorDashboard=async(payload)=>{
  try{
    const response=await contributoraxois?.post(`${ApiConfig?.contributorDashboard}`,payload);
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      // toast.success(
      //   `passsword reset Successfully`,
      //   {
      //     autoClose: 2500,
      //     className: "toast-message",
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
      return response;
    }

  }
  catch(e){
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
    return e;

  }

  
}


export const GetAllcontributorPayments = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await contributoraxois.post(`${ApiConfig.contributorPaymentHistory}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
        
            dispatch(setSoldImagesData(resp?.data?.data));
            dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
          dispatch(
            setSoldImagesDataError({
              isError: true,
              message: errorMessage(e),
            })
          );
        
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
      }
    };
  }
};


export const ContributorHistoryOfSoldImages = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await contributoraxois.post(`${ApiConfig.constributorSoldImagesHistory}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
        
            dispatch(setSoldImagesHistoryData(resp?.data?.data));
            dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
          dispatch(
            setSoldImagesHistoryDataError({
              isError: true,
              message: errorMessage(e),
            })
          );
        
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const ContributorAddQuery=async(payload)=>{
  try{
    const response=await contributoraxois?.post(`${ApiConfig?.contributorAddqueries}`,payload);
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      toast.success(
       response?.data?.message,
        {
          autoClose: 2500,
          className: "toast-message",
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return response;
    }

  }
  catch(e){
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
    return e;

  }

  
}